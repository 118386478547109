export const Logo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="776.000000pt"
      height="236.000000pt"
      viewBox="0 0 776.000000 236.000000"
      preserveAspectRatio="xMidYMid meet">
      <g
        transform="translate(-210.000000,630.000000) scale(0.18,-0.18)"
        fill="#070707"
        stroke="none">
        <g fill={'#EC0026'}>
          <path
            d="M1482 3323 l3 -1108 27 -55 c39 -78 559 -598 633 -632 l50 -23 643
          -3 642 -3 0 331 0 331 -322 -3 c-312 -3 -325 -4 -373 -26 -38 -17 -113 -86
          -320 -292 -149 -149 -278 -270 -286 -270 -8 0 -20 5 -27 12 -9 9 -12 340 -12
          1430 l0 1418 -330 0 -330 0 2 -1107z"
          />
          <path
            d="M2643 4416 c-94 -30 -179 -110 -210 -199 -16 -47 -18 -94 -18 -502
          l0 -450 24 -53 c15 -33 45 -72 83 -107 53 -49 69 -58 171 -90 62 -19 687 -210
          1391 -424 703 -214 1282 -395 1287 -402 4 -7 5 -17 3 -21 -3 -4 -369 -8 -815
          -8 l-809 0 0 -330 0 -330 743 2 742 3 56 28 c66 32 132 99 162 165 22 46 22
          57 22 507 l0 460 -33 67 c-32 65 -81 117 -140 147 -13 7 -636 199 -1385 427
          -749 227 -1373 418 -1386 424 -19 9 -21 13 -11 25 11 13 199 15 1486 15 l1474
          0 0 330 0 330 -1397 -1 c-1121 0 -1406 -3 -1440 -13z"
          />
        </g>
      </g>
    </svg>
  );
};
